import * as styles from '../styles/gallery.module.scss';

import { Link, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import SimpleReactLightbox from 'simple-react-lightbox';

const options = {
  settings: {
    disableWheelControls: true,
    slideAnimationType: 'slide',
  },
  caption: {
    captionColor: '#a6cfa5',
    captionFontFamily: 'Raleway, sans-serif',
    captionFontWeight: '300',
    captionTextTransform: 'uppercase',
    showCaption: false,
  },
};

const Gallery = ({ data }) => {
  const images = data.gallery.edges;
  const thumbnails = data.thumbnails.edges;

  return (
    <Layout>
      <Seo title={'Pilart - Galleria'} description={"Dai un'occhiata alla nostra sala e lezioni!"} />
      <SectionTitle title="GALLERY" />
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div className={styles.galleryWrapper}>
            {thumbnails.map(({ node }, index) => (
              <Link to={images[index].node.childImageSharp.gatsbyImageData.images.fallback.src} key={index}>
                <div key={node.id} className={styles.galleryImage}>
                  <GatsbyImage
                    image={node.childImageSharp.gatsbyImageData}
                    alt={node.base}
                    className={styles.galleryImage}
                    srl_gallery_image="true"
                  />
                </div>
              </Link>
            ))}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Gallery;

export const GalleryPageQuery = graphql`
  query GalleryPageQuery {
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }, sort: { order: ASC, fields: name }) {
      edges {
        node {
          id
          base
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    thumbnails: allFile(filter: { relativeDirectory: { eq: "gallery" } }, sort: { order: ASC, fields: name }) {
      edges {
        node {
          id
          base
          name
          childImageSharp {
            gatsbyImageData(width: 250, height: 250, quality: 90, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
    }
  }
`;
